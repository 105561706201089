"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev_open-api-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/404"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: _layout.default,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    name: "Dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
      });
    },
    meta: {
      title: "首页",
      icon: "dashboard"
    }
  }]
}, {
  path: "/service",
  component: _layout.default,
  name: "Service",
  meta: {
    title: "服务管理",
    icon: "example",
    open: true
  },
  children: [{
    path: "list",
    name: "ServiceList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/serviceList"));
      });
    },
    meta: {
      title: "应用列表"
    }
  }, {
    path: "route",
    name: "Route",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/route"));
      });
    },
    meta: {
      title: "资源管理"
    }
  }, {
    path: "monitor",
    name: "Monitor",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/monitorNew"));
      });
    },
    meta: {
      title: "应用监控"
    }
  }, {
    path: "limit",
    name: "Limit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/limit"));
      });
    },
    meta: {
      title: "限流管理"
    }
  }, {
    path: "blacklist",
    name: "Blacklist",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/ipBlacklist"));
      });
    },
    meta: {
      title: "IP黑名单"
    }
  }, {
    path: "sdk",
    name: "Sdk",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/service/sdk"));
      });
    },
    meta: {
      title: "SDK管理"
    }
  }]
}, {
  path: "/isv",
  component: _layout.default,
  name: "Isv",
  meta: {
    title: "用户管理",
    icon: "user",
    open: true
  },
  children: [{
    path: "list",
    name: "IsvList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/index"));
      });
    },
    meta: {
      title: "用户列表"
    }
  }, {
    path: "workOrder",
    name: "workOrder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/workOrder"));
      });
    },
    meta: {
      title: "工单列表"
    }
  }, {
    path: "workOrderDetail",
    name: "workOrderDetail",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/workOrderDetail"));
      });
    },
    meta: {
      title: "工单详情"
    }
  },
  // {
  //   path: "role",
  //   name: "Role",
  //   component: () => import("@/views/isv/role"),
  //   meta: { title: "角色管理" }
  // },
  {
    path: "keys",
    name: "Keys",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/keys"));
      });
    },
    meta: {
      title: "秘钥管理"
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;