var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.dataForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "工单标题",
                prop: "title",
                required: "",
                rules: [{ required: true, message: "工单标题不能为空" }],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  disabled: "",
                  placeholder: "请填写工单标题",
                },
                model: {
                  value: _vm.dataForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "title", $$v)
                  },
                  expression: "dataForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "工单类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择开通应用", disabled: "" },
                  model: {
                    value: _vm.dataForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "type", $$v)
                    },
                    expression: "dataForm.type",
                  },
                },
                [_c("el-option", { attrs: { label: "开通应用", value: 1 } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "资源列表", prop: "type" } },
            [
              _c("el-tree", {
                attrs: {
                  data: _vm.appList,
                  "show-checkbox": "",
                  "node-key": "id",
                  "default-checked-keys": _vm.dataForm.resourceList,
                  props: _vm.defaultProps,
                },
                on: { check: _vm.resourceTree },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系人邮箱", prop: "contactEmail" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  disabled: "",
                  placeholder: "请填写联系人邮箱",
                },
                model: {
                  value: _vm.dataForm.contactEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "contactEmail", $$v)
                  },
                  expression: "dataForm.contactEmail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "联系人手机号", prop: "contactPhone" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  disabled: "",
                  placeholder: "请填写联系人手机号",
                },
                model: {
                  value: _vm.dataForm.contactPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "contactPhone", $$v)
                  },
                  expression: "dataForm.contactPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  placeholder: "请填写描述",
                  disabled: "",
                  type: "textarea",
                },
                model: {
                  value: _vm.dataForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "description", $$v)
                  },
                  expression: "dataForm.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }