"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchFormData: {
        appKey: "",
        pageIndex: 1,
        pageSize: 10
      },
      pageInfo: {
        list: [],
        total: 0
      },
      roles: [],
      // dialog
      isvDialogVisible: false,
      isvDialogTitle: "新增ISV",
      isvDialogFormData: {
        id: 0,
        status: 1,
        remark: "",
        roleCode: []
      },
      rulesIsvForm: {
        remark: [{
          min: 0,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur"
        }]
      },
      activeName: "first",
      isSaveButtonDisabled: false,
      isvKeysDialogVisible: false,
      isvKeysFormData: {
        appKey: "",
        secret: "",
        publicKeyIsv: "",
        privateKeyIsv: "",
        publicKeyPlatform: "",
        privateKeyPlatform: "",
        signType: "",
        userId: 0
      }
    };
  },
  created: function created() {
    this.loadTable();
    this.loadRouteRole();
  },
  methods: {
    loadTable: function loadTable() {
      this.post("isv.info.page", this.searchFormData, function (resp) {
        this.pageInfo = resp.data;
      });
    },
    loadRouteRole: function loadRouteRole() {
      if (this.roles.length === 0) {
        this.post("role.listall", {}, function (resp) {
          this.roles = resp.data;
        });
      }
    },
    onShowKeys: function onShowKeys(row) {
      this.post("isv.keys.get", {
        appKey: row.appKey
      }, function (resp) {
        var _this = this;
        this.isvKeysDialogVisible = true;
        this.$nextTick(function () {
          Object.assign(_this.isvKeysFormData, resp.data);
          _this.isvKeysFormData.userId = row.userId;
        });
      });
    },
    onSearchTable: function onSearchTable() {
      this.loadTable();
    },
    onTableUpdate: function onTableUpdate(row) {
      var _this2 = this;
      this.isvDialogTitle = "修改ISV";
      this.isvDialogVisible = true;
      this.$nextTick(function () {
        _this2.post("isv.info.get", {
          id: row.id
        }, function (resp) {
          var isvInfo = resp.data;
          var roleList = isvInfo.roleList;
          var roleCode = [];
          for (var i = 0; i < roleList.length; i++) {
            roleCode.push(roleList[i].roleCode);
          }
          isvInfo.roleCode = roleCode;
          Object.assign(this.isvDialogFormData, isvInfo);
        });
      });
    },
    onKeysUpdate: function onKeysUpdate(row) {
      this.$router.push({
        path: "keys?appKey=".concat(row.appKey)
      });
    },
    onExportKeys: function onExportKeys(row) {
      this.post("isv.keys.get", {
        appKey: row.appKey
      }, function (resp) {
        var data = resp.data;
        var appId = data.appKey;
        var privateKeyIsv = data.privateKeyIsv;
        var publicKeyPlatform = data.publicKeyPlatform;
        var content = "AppId\uFF1A".concat(appId, "\n\n\u5F00\u53D1\u8005\u79C1\u94A5\uFF1A\n").concat(privateKeyIsv, "\n\n");
        if (publicKeyPlatform) {
          content = content + "\u5E73\u53F0\u516C\u94A5\uFF1A\n".concat(publicKeyPlatform);
        }
        var filename = "".concat(appId, ".txt");
        this.downloadText(filename, content);
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    },
    onAdd: function onAdd() {
      var _this3 = this;
      this.isvDialogTitle = "新增ISV";
      this.isvDialogVisible = true;
      this.$nextTick(function () {
        _this3.isvDialogFormData.id = 0;
      });
    },
    onIsvDialogSave: function onIsvDialogSave() {
      var _this4 = this;
      this.$refs.isvForm.validate(function (valid) {
        if (valid) {
          _this4.isSaveButtonDisabled = true;
          var uri = _this4.isvDialogFormData.id === 0 ? "isv.info.add" : "isv.info.update";
          _this4.post(uri, _this4.isvDialogFormData, function () {
            this.isvDialogVisible = false;
            this.loadTable();
          });
        }
      });
    },
    onIsvDialogClose: function onIsvDialogClose() {
      this.resetForm("isvForm");
      this.isSaveButtonDisabled = false;
      this.isvDialogFormData.status = 1;
      this.isvDialogFormData.roleCode = [];
    },
    roleRender: function roleRender(row) {
      var html = [];
      var roleList = row.roleList;
      for (var i = 0; i < roleList.length; i++) {
        html.push(roleList[i].description);
      }
      return html.join(", ");
    },
    showKeys: function showKeys() {
      return this.isvKeysFormData.signType === 1;
    }
  }
};
exports.default = _default;