"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tabsData: [],
      tabsActive: "",
      serviceTextLimitSize: 20,
      filterText: "",
      treeData: [],
      tableData: [],
      serviceId: "",
      isCustomService: false,
      searchFormData: {
        id: "",
        serviceId: "",
        permission: 0,
        needToken: 0,
        pageIndex: 1,
        pageSize: 10
      },
      pageInfo: {
        rows: [],
        total: 0
      },
      defaultProps: {
        children: "children",
        label: "label"
      },
      routeDialogTitle: "修改路由",
      // dialog
      routeDialogFormData: {
        id: "",
        name: "",
        version: "1.0",
        uri: "",
        path: "",
        status: 1,
        mergeResult: 1,
        ignoreValidate: 0
      },
      routeDialogFormRules: {
        name: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur"
        }],
        version: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur"
        }],
        uri: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur"
        }],
        path: [{
          min: 0,
          max: 100,
          message: "长度不能超过 100 个字符",
          trigger: "blur"
        }]
      },
      routeDialogVisible: false,
      roles: [],
      authDialogFormData: {
        routeId: "",
        roleCode: []
      },
      authDialogVisible: false,
      // addService
      addServiceDialogVisible: false,
      addServiceForm: {
        serviceId: ""
      },
      addServiceFormRules: {
        serviceId: [{
          required: true,
          message: "请输入服务名称",
          trigger: "blur"
        }, {
          min: 1,
          max: 100,
          message: "长度在 1 到 100 个字符",
          trigger: "blur"
        }]
      },
      // 详情
      detailDialogVisible: false,
      detailForm: {},
      detailFormRules: {
        version: [{
          required: true,
          message: "请输入版本号",
          trigger: "blur"
        }],
        path: [{
          required: true,
          message: "请输入接口路径",
          trigger: "blur"
        }]
      }
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.serviceTree.filter(val);
    }
  },
  created: function created() {
    this.loadTabs();
    this.loadRouteRole();
  },
  methods: {
    /* 编辑 */detail: function detail(info) {
      this.post("route.get", {
        serviceId: info.serviceId,
        id: info.id
      }, function (resp) {
        var _this = this;
        this.detailDialogVisible = true;
        this.$nextTick(function () {
          var data = resp.data;
          _this.detailForm = data;
        });
      });
    },
    /* 编辑确认弹窗 */detailSure: function detailSure() {
      var _this2 = this;
      this.$refs.detailForm.validate(function (valid) {
        if (valid) {
          _this2.post("service.route.set", _this2.detailForm, function () {
            this.detailDialogVisible = false;
            this.tip("保存成功");
          });
        }
      });
    },
    loadTabs: function loadTabs() {
      this.post("registry.service.list", {}, function (resp) {
        var _this3 = this;
        this.tabsData = resp.data;
        this.$nextTick(function () {
          if (_this3.tabsData.length > 0) {
            _this3.tabsActive = _this3.tabsData[0];
            _this3.loadRouteData();
          }
        });
      });
    },
    // 加载树
    loadTree: function loadTree() {
      this.post("registry.service.list", {}, function (resp) {
        var _this4 = this;
        var respData = resp.data;
        this.treeData = this.convertToTreeData(respData, 0);
        this.$nextTick(function () {
          // 高亮已选中的
          if (_this4.serviceId) {
            _this4.$refs.serviceTree.setCurrentKey(_this4.serviceId);
          }
        });
      });
    },
    // 树搜索
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    onNodeClick: function onNodeClick(data, node, tree) {
      if (data.parentId) {
        this.serviceId = data.label;
        this.searchFormData.serviceId = this.serviceId;
        this.isCustomService = Boolean(data.custom);
        this.loadTable();
      }
    },
    selectTab: function selectTab() {
      this.loadRouteData();
    },
    loadRouteData: function loadRouteData() {
      this.serviceId = this.tabsActive;
      this.searchFormData.serviceId = this.serviceId;
      this.loadTable();
    },
    /**
     * 数组转成树状结构
     * @param data 数据结构 [{
        "_parentId": 14,
        "gmtCreate": "2019-01-15 09:44:38",
        "gmtUpdate": "2019-01-15 09:44:38",
        "id": 15,
        "isShow": 1,
        "name": "用户注册",
        "orderIndex": 10000,
        "parentId": 14
    },...]
     * @param pid 初始父节点id，一般是0
     * @return 返回结果 [{
      label: '一级 1',
      children: [{
        label: '二级 1-1',
        children: [{
          label: '三级 1-1-1'
        }]
      }]
    }
     */
    convertToTreeData: function convertToTreeData(data, pid) {
      var result = [];
      var root = {
        label: data.length === 0 ? "无服务" : "服务列表",
        parentId: pid
      };
      var children = [];
      for (var i = 0; i < data.length; i++) {
        var child = {
          parentId: 1,
          label: data[i]
        };
        children.push(child);
      }
      root.children = children;
      result.push(root);
      return result;
    },
    getNameVersion: function getNameVersion(row) {
      return row.name + (row.version ? " (" + row.version + ")" : "");
    },
    // table
    loadTable: function loadTable(param) {
      if (!this.searchFormData.serviceId) {
        this.tip("请选择一个服务", "error");
        return;
      }
      var postData = param || this.searchFormData;
      this.post("route.page", postData, function (resp) {
        this.pageInfo = resp.data;
      });
    },
    onSearchTable: function onSearchTable() {
      this.searchFormData.pageIndex = 1;
      this.loadTable();
    },
    onTableUpdate: function onTableUpdate(row) {
      var _this5 = this;
      this.routeDialogTitle = "修改路由";
      this.routeDialogVisible = true;
      this.$nextTick(function () {
        Object.assign(_this5.routeDialogFormData, row);
      });
    },
    onTableAuth: function onTableAuth(row) {
      this.authDialogFormData.routeId = row.id;
      var searchData = {
        id: row.id,
        serviceId: this.serviceId
      };
      this.post("route.role.get", searchData, function (resp) {
        var roleList = resp.data;
        var roleCodes = [];
        for (var i = 0; i < roleList.length; i++) {
          roleCodes.push(roleList[i].roleCode);
        }
        this.authDialogFormData.roleCode = roleCodes;
        this.authDialogVisible = true;
      });
    },
    onTableDel: function onTableDel(row) {
      this.confirm("\u786E\u8BA4\u8981\u5220\u9664\u8DEF\u7531\u3010".concat(row.id, "\u3011\u5417\uFF1F"), function (done) {
        var data = {
          serviceId: this.serviceId,
          id: row.id
        };
        this.post("route.del", data, function () {
          done();
          this.tip("删除成功");
          this.loadTable();
        });
      });
    },
    // element-ui switch开关 点击按钮后，弹窗确认后再改变开关状态
    // https://blog.csdn.net/Gomeer/article/details/103697593
    onChangeStatus: function onChangeStatus(row) {
      var newStatus = row.status;
      var oldStatus = newStatus === 1 ? 2 : 1;
      // 先将状态改成原来的值
      row.status = oldStatus;
      var nameVersion = this.getNameVersion(row);
      var msg = oldStatus === 1 ? "\u786E\u8BA4\u8981\u7981\u7528 ".concat(nameVersion, " \u5417\uFF1F") : "\u786E\u8BA4\u8981\u542F\u7528 ".concat(nameVersion, " \u5417\uFF1F");
      this.confirm(msg, function (done) {
        var data = {
          id: row.id,
          status: newStatus
        };
        // 'route.role.update', this.authDialogFormData
        this.post("route.status.update", data, function () {
          done();
          row.status = newStatus;
        });
      }, function (done) {
        row.status = oldStatus;
        done();
      });
    },
    onCloseRouteDialog: function onCloseRouteDialog() {
      this.resetForm("routeDialogFormRef");
    },
    routePropDisabled: function routePropDisabled() {
      if (!this.routeDialogFormData.id) {
        return false;
      }
      return !this.isCustomService;
    },
    loadRouteRole: function loadRouteRole() {
      if (this.roles.length === 0) {
        this.post("role.listall", {}, function (resp) {
          this.roles = resp.data;
        });
      }
    },
    addRoute: function addRoute() {
      var _this6 = this;
      this.routeDialogTitle = "新建路由";
      this.routeDialogVisible = true;
      this.$nextTick(function () {
        Object.assign(_this6.routeDialogFormData, {
          id: ""
        });
      });
    },
    roleRender: function roleRender(row) {
      var html = [];
      var roles = row.roles;
      for (var i = 0; i < roles.length; i++) {
        html.push(roles[i].description);
      }
      return html.length > 0 ? html.join(", ") : "点击授权";
    },
    onRouteDialogSave: function onRouteDialogSave() {
      var _this7 = this;
      this.$refs.routeDialogFormRef.validate(function (valid) {
        if (valid) {
          var uri = _this7.routeDialogFormData.id ? "route.status.update" : "route.add";
          _this7.routeDialogFormData.serviceId = _this7.serviceId;
          _this7.post(uri, _this7.routeDialogFormData, function () {
            this.routeDialogVisible = false;
            this.loadTable();
          });
        }
      });
    },
    onAuthDialogSave: function onAuthDialogSave() {
      this.post("route.role.update", this.authDialogFormData, function () {
        this.authDialogVisible = false;
        this.loadTable();
      });
    },
    addService: function addService() {
      this.addServiceDialogVisible = true;
    },
    closeAddServiceDlg: function closeAddServiceDlg() {
      this.$refs.addServiceForm.resetFields();
    },
    onAddService: function onAddService() {
      var _this8 = this;
      this.$refs.addServiceForm.validate(function (valid) {
        if (valid) {
          _this8.post("service.custom.add", _this8.addServiceForm, function (resp) {
            this.addServiceDialogVisible = false;
            this.tip("添加成功");
            this.loadTree();
          });
        }
      });
    },
    onDelService: function onDelService(data) {
      var serviceId = data.serviceId;
      this.confirm("确认要删除服务" + serviceId + "吗，【对应的路由配置会一起删除】", function (done) {
        var postData = {
          serviceId: serviceId
        };
        this.post("service.custom.del", postData, function () {
          done();
          this.tip("删除成功");
          this.loadTree();
        });
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    }
  }
};
exports.default = _default;