var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.pageInfo.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "工单id" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "contactPhone", label: "联系人" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "title", label: "工单标题" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单描述" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("span", [_vm._v("开通应用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type === 2
                      ? _c("span", [_vm._v("平台反馈")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "warning",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("待处理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("已处理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("已关闭")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.detail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(scope.row, 2)
                              },
                            },
                          },
                          [_c("el-tag", [_vm._v("处理")])],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticStyle: { "margin-top": "5px" },
        attrs: {
          background: "",
          "current-page": _vm.searchFormData.pageIndex,
          "page-size": _vm.searchFormData.pageSize,
          "page-sizes": [5, 10, 20, 40],
          total: _vm.pageInfo.total,
          layout: "total, sizes, prev, pager, next",
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.onPageIndexChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "秘钥信息", visible: _vm.isvKeysDialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.isvKeysDialogVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm("isvKeysFrom")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "isvKeysFrom",
              staticClass: "key-view",
              attrs: {
                model: _vm.isvKeysFormData,
                "label-width": "160px",
                size: "mini",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "appId" } }, [
                _c("span", [_vm._v(_vm._s(_vm.isvKeysFormData.appKey))]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isvKeysFormData.signType === 2,
                      expression: "isvKeysFormData.signType === 2",
                    },
                  ],
                  attrs: { label: "secret" },
                },
                [_c("span", [_vm._v(_vm._s(_vm.isvKeysFormData.secret))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isvKeysDialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }