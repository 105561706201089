"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var regexIP = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    var ipValidator = function ipValidator(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入IP'));
      } else {
        if (!regexIP.test(value)) {
          callback(new Error('IP格式不正确'));
        }
        callback();
      }
    };
    return {
      searchFormData: {
        ip: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageInfo: {
        rows: [],
        total: 0
      },
      dialogVisible: false,
      dialogTitle: '',
      dialogFormData: {
        id: 0,
        ip: '',
        remark: ''
      },
      dialogFormRules: {
        ip: [{
          validator: ipValidator,
          trigger: 'blur'
        }, {
          min: 1,
          max: 64,
          message: '长度在 1 到 64 个字符',
          trigger: 'blur'
        }],
        remark: [{
          max: 100,
          message: '不能超过 100 个字符',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.loadTable();
  },
  methods: {
    loadTable: function loadTable() {
      this.post('ip.blacklist.page', this.searchFormData, function (resp) {
        this.pageInfo = resp.data;
      });
    },
    onTableUpdate: function onTableUpdate(row) {
      var _this = this;
      this.dialogTitle = '修改IP';
      this.dialogVisible = true;
      this.$nextTick(function () {
        Object.assign(_this.dialogFormData, row);
      });
    },
    onTableDelete: function onTableDelete(row) {
      this.confirm("\u786E\u8BA4\u8981\u79FB\u9664IP\u3010".concat(row.ip, "\u3011\u5417\uFF1F"), function (done) {
        var data = {
          id: row.id
        };
        this.post('ip.blacklist.del', data, function () {
          done();
          this.tip('删除成功');
          this.loadTable();
        });
      });
    },
    onDialogSave: function onDialogSave() {
      var _this2 = this;
      this.$refs.dialogForm.validate(function (valid) {
        if (valid) {
          var uri = _this2.dialogFormData.id ? 'ip.blacklist.update' : 'ip.blacklist.add';
          _this2.post(uri, _this2.dialogFormData, function () {
            this.dialogVisible = false;
            this.loadTable();
          });
        }
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onAdd: function onAdd() {
      this.dialogTitle = '新增IP';
      this.dialogVisible = true;
      this.dialogFormData.id = 0;
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    }
  }
};
exports.default = _default;