var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.tabsData.length === 0
        ? _c("div", [_vm._v("\n    无服务\n  ")])
        : _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.selectTab },
                  model: {
                    value: _vm.tabsActive,
                    callback: function ($$v) {
                      _vm.tabsActive = $$v
                    },
                    expression: "tabsActive",
                  },
                },
                _vm._l(_vm.tabsData, function (tabName) {
                  return _c("el-tab-pane", {
                    key: tabName,
                    attrs: { label: tabName, name: tabName },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    model: _vm.searchFormData,
                    size: "mini",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "路由名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: true,
                          placeholder: "输入接口名或版本号",
                        },
                        model: {
                          value: _vm.searchFormData.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchFormData, "id", $$v)
                          },
                          expression: "searchFormData.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.searchFormData.permission,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchFormData, "permission", $$v)
                            },
                            expression: "searchFormData.permission",
                          },
                        },
                        [_vm._v("授权接口")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.searchFormData.needToken,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchFormData, "needToken", $$v)
                            },
                            expression: "searchFormData.needToken",
                          },
                        },
                        [_vm._v("需要token")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            "native-type": "submit",
                          },
                          on: { click: _vm.onSearchTable },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCustomService,
                      expression: "isCustomService",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addRoute($event)
                    },
                  },
                },
                [_vm._v("\n      新建路由\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.pageInfo.rows,
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "接口名 (版本号)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getNameVersion(scope.row)) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "path", label: "接口路径" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.path) +
                                "\n        "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roles",
                      label: "访问权限",
                      width: "150",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            Number(scope.row.permission) === 0
                              ? _c("span", [
                                  _vm._v("\n            （公开）\n          "),
                                ])
                              : _c("span", [
                                  _vm._v("\n            需授权\n          "),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ignoreValidate",
                      label: "签名校验",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.ignoreValidate === 0
                              ? _c("span", [_vm._v("校验")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.ignoreValidate === 1
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#E6A23C" } },
                                  [_vm._v("不校验")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mergeResult",
                      label: "统一格式输出",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.mergeResult === 1
                              ? _c("span", [_vm._v("是")])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.mergeResult === 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#E6A23C" } },
                                  [_vm._v("否")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "needToken",
                      label: "需要token",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.needToken === 1
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      color: "#303133",
                                    },
                                  },
                                  [_vm._v("是")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.needToken === 0
                              ? _c("span", [_vm._v("否")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "roles",
                      label: "参数加密",
                      width: "150",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            Number(scope.row.encryption) === 0
                              ? _c("span", [
                                  _vm._v("\n            否\n          "),
                                ])
                              : _c("span", [_vm._v("是")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                                "active-value": 1,
                                "inactive-value": 2,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeStatus(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "操作", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { "margin-top": "5px" },
                attrs: {
                  background: "",
                  "current-page": _vm.searchFormData.pageIndex,
                  "page-size": _vm.searchFormData.pageSize,
                  "page-sizes": [10, 20, 40],
                  total: _vm.pageInfo.total,
                  layout: "total, sizes, prev, pager, next",
                },
                on: {
                  "size-change": _vm.onSizeChange,
                  "current-change": _vm.onPageIndexChange,
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.routeDialogTitle,
            visible: _vm.routeDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.routeDialogVisible = $event
            },
            close: _vm.onCloseRouteDialog,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "routeDialogFormRef",
              attrs: {
                model: _vm.routeDialogFormData,
                rules: _vm.routeDialogFormRules,
                "label-width": "180px",
                size: "mini",
              },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                model: {
                  value: _vm.routeDialogFormData.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.routeDialogFormData, "id", $$v)
                  },
                  expression: "routeDialogFormData.id",
                },
              }),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "接口名 (版本号)" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.routeDialogFormData.name +
                        (_vm.routeDialogFormData.version
                          ? " (" + _vm.routeDialogFormData.version + ")"
                          : "")
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "签名校验" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.routeDialogFormData.ignoreValidate ? "不校验" : "校验"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "统一格式输出" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.routeDialogFormData.mergeResult === 1 ? "是" : "否"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "需要token" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.routeDialogFormData.needToken === 1 ? "是" : "否"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.routeDialogFormData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.routeDialogFormData, "status", $$v)
                        },
                        expression: "routeDialogFormData.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, name: "status" } }, [
                        _vm._v("启用"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { color: "#F56C6C" },
                          attrs: { label: 2, name: "status" },
                        },
                        [_vm._v("禁用")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.routeDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onRouteDialogSave },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "路由授权",
            visible: _vm.authDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.authDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.authDialogFormData,
                "label-width": "120px",
                size: "mini",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "路由ID" } }, [
                _c("span", [_vm._v(_vm._s(_vm.authDialogFormData.routeId))]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.authDialogFormData.roleCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.authDialogFormData, "roleCode", $$v)
                        },
                        expression: "authDialogFormData.roleCode",
                      },
                    },
                    _vm._l(_vm.roles, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.roleCode, attrs: { label: item.roleCode } },
                        [_vm._v(_vm._s(item.description))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.authDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onAuthDialogSave },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加服务",
            visible: _vm.addServiceDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addServiceDialogVisible = $event
            },
            close: _vm.closeAddServiceDlg,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addServiceForm",
              attrs: {
                model: _vm.addServiceForm,
                rules: _vm.addServiceFormRules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "服务名（serviceId）", prop: "serviceId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "服务名，如：order-service" },
                    model: {
                      value: _vm.addServiceForm.serviceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.addServiceForm, "serviceId", $$v)
                      },
                      expression: "addServiceForm.serviceId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addServiceDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAddService } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "路由详情",
            visible: _vm.detailDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
            close: function ($event) {
              _vm.detailDialogVisible = false
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "detailForm",
              attrs: {
                model: _vm.detailForm,
                rules: _vm.detailFormRules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接口名(版本号)", prop: "version" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写接口名版本号" },
                    model: {
                      value: _vm.detailForm.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "version", $$v)
                      },
                      expression: "detailForm.version",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "接口路径", prop: "path" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写接口路径" },
                    model: {
                      value: _vm.detailForm.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "path", $$v)
                      },
                      expression: "detailForm.path",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否开启访问权限", prop: "permission" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 0,
                      "inactive-value": 1,
                    },
                    model: {
                      value: _vm.detailForm.permission,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "permission", $$v)
                      },
                      expression: "detailForm.permission",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否校验签名", prop: "ignoreValidate" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.detailForm.ignoreValidate,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "ignoreValidate", $$v)
                      },
                      expression: "detailForm.ignoreValidate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否需要统一格式输出", prop: "serviceId" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.detailForm.mergeResult,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "mergeResult", $$v)
                      },
                      expression: "detailForm.mergeResult",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否需要token", prop: "serviceId" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.detailForm.needToken,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "needToken", $$v)
                      },
                      expression: "detailForm.needToken",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否需要参数加密", prop: "serviceId" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.detailForm.encryption,
                      callback: function ($$v) {
                        _vm.$set(_vm.detailForm, "encryption", $$v)
                      },
                      expression: "detailForm.encryption",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.detailDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.detailSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }