"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.string.ends-with");
var _vue = _interopRequireDefault(require("vue"));
var _axios = _interopRequireDefault(require("axios"));
var _auth = require("./auth");
/*
注册全局方法
 */

// 创建axios实例
var client = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API + "/api",
  // api 的 base_url
  timeout: 60000,
  // 请求超时时间,60秒
  headers: {
    "x-userpool-id": process.env.VUE_APP_USER_POOL,
    Authorization: "Bearer ".concat(sessionStorage.getItem("token"))
  }
});
Object.assign(_vue.default.prototype, {
  /**
   * 请求接口
   * @param uri uri，如：goods.get,goods.get/1.0
   * @param data 请求数据
   * @param callback 成功时回调
   * @param errorCallback 错误时回调
   */
  post: function post(uri, data, callback, errorCallback) {
    var that = this;
    if (uri.indexOf("https://") !== -1 && uri.indexOf("http://") !== -1) {
      if (!uri.endsWith("/")) {
        uri = uri + "/";
      }
      if (!uri.startsWith("/")) {
        uri = "/" + uri;
      }
    }
    client.post(uri, data).then(function (response) {
      var resp = response.data;
      console.log(response, "response");
      var code = resp.code;
      if (!code || code === "-9") {
        that.$message.error(resp.msg || "系统错误");
        return;
      }
      if (code === "-100" || code === "18" || code === "21") {
        // 未登录
        that.logout();
        return;
      }
      if (code === "0" || code === 200) {
        // 成功
        callback && callback.call(that, resp);
      } else {
        that.$message.error(resp.msg);
      }
    }).catch(function (error) {
      console.error("err" + error); // for debug
      errorCallback && errorCallback(error);
      that.$message.error(error.message);
    });
  },
  get: function get(uri, data, callback, errorCallback) {
    var that = this;
    if (uri.indexOf("https://") !== -1 && uri.indexOf("http://") !== -1) {
      if (!uri.endsWith("/")) {
        uri = uri + "/";
      }
      if (!uri.startsWith("/")) {
        uri = "/" + uri;
      }
    }
    client.get(uri, data).then(function (response) {
      var resp = response.data;
      console.log(response, "response");
      var code = resp.code;
      if (!code || code === "-9") {
        that.$message.error(resp.msg || "系统错误");
        return;
      }
      if (code === "-100" || code === "18" || code === "21") {
        // 未登录
        that.logout();
        return;
      }
      if (code === "0" || code === 200) {
        // 成功
        callback && callback.call(that, resp);
      } else {
        that.$message.error(resp.msg);
      }
    }).catch(function (error) {
      console.error("err" + error); // for debug
      errorCallback && errorCallback(error);
      that.$message.error(error.message);
    });
  },
  put: function put(uri, data, callback, errorCallback) {
    var that = this;
    if (uri.indexOf("https://") !== -1 && uri.indexOf("http://") !== -1) {
      if (!uri.endsWith("/")) {
        uri = uri + "/";
      }
      if (!uri.startsWith("/")) {
        uri = "/" + uri;
      }
    }
    client.put(uri, data).then(function (response) {
      var resp = response.data;
      console.log(response, "response");
      var code = resp.code;
      if (!code || code === "-9") {
        that.$message.error(resp.msg || "系统错误");
        return;
      }
      if (code === "-100" || code === "18" || code === "21") {
        // 未登录
        that.logout();
        return;
      }
      if (code === "0" || code === 200) {
        // 成功
        callback && callback.call(that, resp);
      } else {
        that.$message.error(resp.msg);
      }
    }).catch(function (error) {
      console.error("err" + error); // for debug
      errorCallback && errorCallback(error);
      that.$message.error(error.message);
    });
  },
  /**
   * tip，使用方式：this.tip('操作成功')，this.tip('错误', 'error')
   * @param msg 内容
   * @param type success / info / warning / error
   * @param stay 停留几秒，默认3秒
   */
  tip: function tip(msg, type, stay) {
    stay = parseInt(stay) || 3;
    this.$message({
      message: msg,
      type: type || "success",
      duration: stay * 1000
    });
  },
  /**
   * 提醒框
   * @param msg 消息
   * @param okHandler 成功回调
   * @param cancelHandler
   */
  confirm: function confirm(msg, okHandler, cancelHandler) {
    var that = this;
    this.$confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
      beforeClose: function beforeClose(action, instance, done) {
        if (action === "confirm") {
          okHandler.call(that, done);
        } else if (action === "cancel") {
          if (cancelHandler) {
            cancelHandler.call(that, done);
          } else {
            done();
          }
        } else {
          done();
        }
      }
    }).catch(function () {});
  },
  /**
   *  文件必须放在public下面
   * @param path 相对于public文件夹路径，如文件在public/static/sign.md，填：static/sign.md
   * @param callback 回调函数，函数参数是文件内容
   */
  getFile: function getFile(path, callback) {
    _axios.default.get(path).then(function (response) {
      callback.call(this, response.data);
    });
  },
  downloadText: function downloadText(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },
  /**
   * 重置表单
   * @param formName 表单元素的ref
   */
  resetForm: function resetForm(formName) {
    var frm = this.$refs[formName];
    frm && frm.resetFields();
  },
  logout: function logout() {
    (0, _auth.removeToken)();
    var fullPath = this.$route.fullPath;
    if (fullPath.indexOf("login?redirect") === -1) {
      this.$router.push({
        path: "/login?redirect=".concat(fullPath)
      });
    }
  }
});