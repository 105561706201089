"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var regex = /^\S+(,\S+)*$/;
    var userKeyContentValidator = function userKeyContentValidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("不能为空"));
      } else {
        if (!regex.test(value)) {
          callback(new Error("格式不正确"));
        }
        callback();
      }
    };
    return {
      searchFormData: {
        serviceId: ""
      },
      blackList: ["sop-gateway", "sop-admin"],
      grayDialogVisible: false,
      grayForm: {
        serviceId: "",
        userKeyContent: "",
        onlyUpdateGrayUserkey: false,
        grayRouteConfigList: []
      },
      tabsActiveName: "first",
      routeList: [],
      selectNameVersion: [],
      grayFormRules: {
        userKeyContent: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          validator: userKeyContentValidator,
          trigger: "blur"
        }]
      },
      tableData: [],
      // 设置路由
      routeDialogVisible: false,
      routeForm: {
        serviceId: "",
        // 服务id
        predicates: "",
        // 路由断言
        uri: "" // 路由url
      },

      routeFormRules: {
        predicates: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }],
        uri: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.loadTable();
  },
  methods: {
    loadTable: function loadTable() {
      this.post("service.instance.list", this.searchFormData, function (resp) {
        this.tableData = this.buildTreeData(resp.data);
      });
    },
    loadRouteList: function loadRouteList(serviceId) {
      this.post("route.list/1.2", {
        serviceId: serviceId.toLowerCase()
      }, function (resp) {
        this.routeList = resp.data;
      });
    },
    getGraySelectData: function getGraySelectData(oldRouteId) {
      return this.routeList.filter(function (routeNew) {
        return oldRouteId !== routeNew.id && oldRouteId.indexOf(routeNew.name) > -1;
      });
    },
    buildTreeData: function buildTreeData(data) {
      data.forEach(function (ele) {
        var parentId = ele.parentId;
        if (parentId === 0) {
          // 是根元素 ,不做任何操作,如果是正常的for-i循环,可以直接continue.
        } else {
          // 如果ele是子元素的话 ,把ele扔到他的父亲的child数组中.
          data.forEach(function (d) {
            if (d.id === parentId) {
              var childArray = d.children;
              if (!childArray) {
                childArray = [];
              }
              childArray.push(ele);
              d.children = childArray;
            }
          });
        }
      });
      // 去除重复元素
      data = data.filter(function (ele) {
        return ele.parentId === 0;
      });
      return data;
    },
    onSearchTable: function onSearchTable() {
      this.loadTable();
    },
    onDisable: function onDisable(row) {
      this.confirm("\u786E\u5B9A\u8981\u7981\u7528 ".concat(row.serviceId, "(").concat(row.ipPort, ") \u5417?"), function (done) {
        this.post("service.instance.offline", row, function () {
          this.tip("禁用成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    onEnable: function onEnable(row) {
      this.confirm("\u786E\u5B9A\u8981\u542F\u7528 ".concat(row.serviceId, "(").concat(row.ipPort, ") \u5417?"), function (done) {
        this.post("service.instance.online", row, function () {
          this.tip("启用成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    doEnvOnline: function doEnvOnline(row, callback) {
      this.post("service.instance.env.online", row, function () {
        callback && callback.call(this);
      });
    },
    onEnvPreOpen: function onEnvPreOpen(row) {
      this.confirm("\u786E\u5B9A\u8981\u5F00\u542F ".concat(row.serviceId, "(").concat(row.ipPort, ") \u9884\u53D1\u5E03\u5417?"), function (done) {
        this.post("service.instance.env.pre.open", row, function () {
          this.tip("预发布成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    onEnvPreClose: function onEnvPreClose(row) {
      this.confirm("\u786E\u5B9A\u8981\u7ED3\u675F ".concat(row.serviceId, "(").concat(row.ipPort, ") \u9884\u53D1\u5E03\u5417?"), function (done) {
        this.doEnvOnline(row, function () {
          this.tip("操作成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    onEnvGrayOpen: function onEnvGrayOpen(row) {
      this.confirm("\u786E\u5B9A\u8981\u5F00\u542F ".concat(row.serviceId, "(").concat(row.ipPort, ") \u7070\u5EA6\u5417?"), function (done) {
        this.post("service.instance.env.gray.open", row, function () {
          this.tip("开启成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    onEnvGrayClose: function onEnvGrayClose(row) {
      this.confirm("\u786E\u5B9A\u8981\u7ED3\u675F ".concat(row.serviceId, "(").concat(row.ipPort, ") \u7070\u5EA6\u5417?"), function (done) {
        this.doEnvOnline(row, function () {
          this.tip("操作成功");
          done();
          this.loadTableDelay();
        });
      });
    },
    /* 设置灰度参数 */
    onGrayConfigUpdate: function onGrayConfigUpdate(row) {
      var serviceId = row.serviceId;
      this.loadRouteList(serviceId);
      this.post("service.gray.config.get", {
        serviceId: serviceId
      }, function (resp) {
        var _this = this;
        this.grayDialogVisible = true;
        this.$nextTick(function () {
          var data = resp.data;
          Object.assign(_this.grayForm, {
            serviceId: serviceId,
            userKeyContent: data.userKeyContent || "",
            grayRouteConfigList: _this.createGrayRouteConfigList(data.nameVersionContent)
          });
        });
      });
    },
    /* 灰度参数确认事件 */
    onGrayConfigSave: function onGrayConfigSave() {
      var _this2 = this;
      this.$refs.grayForm.validate(function (valid) {
        if (valid) {
          var nameVersionContents = [];
          var grayRouteConfigList = _this2.grayForm.grayRouteConfigList;
          for (var i = 0; i < grayRouteConfigList.length; i++) {
            var config = grayRouteConfigList[i];
            nameVersionContents.push(config.oldRouteId + "=" + config.newVersion);
          }
          _this2.grayForm.nameVersionContent = nameVersionContents.join(",");
          _this2.post("service.gray.config.save", _this2.grayForm, function () {
            this.grayDialogVisible = false;
            this.tip("保存成功");
          });
        }
      });
    },
    /* 设置路由 */
    onRouteConfigUpdate: function onRouteConfigUpdate(row) {
      var serviceId = row.serviceId;
      this.post("service.route.get", {
        serviceId: serviceId
      }, function (resp) {
        var _this3 = this;
        this.routeDialogVisible = true;
        this.$nextTick(function () {
          var data = resp.data;
          Object.assign(_this3.routeForm, {
            serviceId: serviceId,
            predicates: data.predicates || "",
            uri: data.uri || ""
          });
        });
      });
    },
    /* 设置路由确认事件 */onRouteConfigSave: function onRouteConfigSave() {
      var _this4 = this;
      this.$refs.routeForm.validate(function (valid) {
        if (valid) {
          _this4.post("service.route.set", _this4.routeForm, function () {
            this.routeDialogVisible = false;
            this.tip("保存成功");
          });
        }
      });
    },
    createGrayRouteConfigList: function createGrayRouteConfigList(nameVersionContent) {
      if (!nameVersionContent) {
        return [{
          oldRouteId: "",
          newVersion: "",
          key: Date.now()
        }];
      }
      var list = [];
      var arr = nameVersionContent.split(",");
      for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        var elArr = el.split("=");
        list.push({
          oldRouteId: elArr[0],
          newVersion: elArr[1],
          key: Date.now()
        });
      }
      return list;
    },
    onChangeOldRoute: function onChangeOldRoute(config) {
      config.newVersion = "";
    },
    addNameVersion: function addNameVersion() {
      this.grayForm.grayRouteConfigList.push({
        oldRouteId: "",
        newVersion: "",
        key: Date.now()
      });
    },
    removeNameVersion: function removeNameVersion(item) {
      var index = this.grayForm.grayRouteConfigList.indexOf(item);
      if (index !== -1) {
        this.grayForm.grayRouteConfigList.splice(index, 1);
      }
    },
    renderServiceName: function renderServiceName(row) {
      var instanceCount = "";
      // 如果是父节点
      if (row.parentId === 0) {
        var children = row.children || [];
        var childCount = children.length;
        var onlineCount = children.filter(function (el) {
          return el.status === "UP";
        }).length;
        instanceCount = "(".concat(onlineCount, "/").concat(childCount, ")");
      }
      return row.serviceId + instanceCount;
    },
    loadTableDelay: function loadTableDelay() {
      var that = this;
      setTimeout(function () {
        that.loadTable();
      }, 2000);
    }
  }
};
exports.default = _default;