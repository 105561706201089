"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var appFormDataInit = function appFormDataInit() {
  return {
    id: 0,
    name: '',
    version: '',
    content: '',
    extContent: ''
  };
};
var _default = {
  data: function data() {
    return {
      searchFormData: {},
      sdkDownloadConfig: [],
      sdkConfigs: [],
      sdkDlgTitle: '',
      sdkDlgAddShow: false,
      sdkFormUpdateData: appFormDataInit(),
      sdkFormAddData: appFormDataInit(),
      sdkFormLoading: false,
      sdkFormRule: {
        name: [{
          required: true,
          message: '请选择语言',
          trigger: 'blur'
        }],
        version: [{
          required: true,
          message: '请填版本',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请填写URL',
          trigger: 'blur'
        }],
        extContent: [{
          required: true,
          message: '请填写调用示例',
          trigger: 'blur'
        }]
      },
      downloadUrl: '',
      list: []
    };
  },
  created: function created() {
    this.loadLangSelector();
    this.loadTable();
  },
  methods: {
    loadLangSelector: function loadLangSelector() {
      var _this = this;
      this.getFile("static/sdkConfig.json?q=".concat(new Date().getTime()), function (content) {
        _this.sdkConfigs = content.langList;
      });
    },
    loadTable: function loadTable() {
      var _this2 = this;
      this.post('isp.sdk.list', this.searchFormData, function (resp) {
        _this2.list = resp.data;
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    },
    onAddSdk: function onAddSdk() {
      this.sdkDlgTitle = '添加SDK';
      this.sdkFormAddData = appFormDataInit();
      this.sdkDlgAddShow = true;
    },
    onSdkUpdate: function onSdkUpdate(row) {
      this.sdkDlgTitle = '修改SDK';
      this.sdkFormAddData = appFormDataInit();
      Object.assign(this.sdkFormAddData, row);
      this.sdkDlgAddShow = true;
    },
    onSdkDelete: function onSdkDelete(row) {
      var _this3 = this;
      this.confirm("\u786E\u8BA4\u8981\u5220\u9664\u3010".concat(row.name, "\u3011\u5417\uFF1F"), function (done) {
        _this3.post('isp.sdk.delete', {
          id: row.id
        }, function (resp) {
          done();
          _this3.tip('删除成功');
          _this3.loadTable();
        });
      });
    },
    onSubmitForm: function onSubmitForm() {
      var _this4 = this;
      this.$refs.sdkAddForm.validate(function (valid) {
        if (valid) {
          var uri = _this4.sdkFormAddData.id ? 'isp.sdk.update' : 'isp.sdk.add';
          _this4.post(uri, _this4.sdkFormAddData, function () {
            this.sdkDlgAddShow = false;
            this.loadTable();
          });
        }
      });
    }
  }
};
exports.default = _default;