"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchFormData: {
        routeId: '',
        serviceId: '',
        pageIndex: 1,
        pageSize: 20
      },
      pageInfo: {
        list: [],
        total: 0
      },
      logDetailVisible: false,
      errorMsgFormData: {
        routeId: '',
        instanceId: '',
        pageIndex: 1,
        pageSize: 5
      },
      errorMsgData: {
        title: '',
        name: '',
        version: '',
        pageInfo: {
          rows: [],
          total: 0
        }
      }
    };
  },
  created: function created() {
    this.loadTable();
  },
  methods: {
    loadTable: function loadTable() {
      this.post('monitornew.data.page', this.searchFormData, function (resp) {
        this.pageInfo = resp.data;
      });
    },
    loadErrorData: function loadErrorData() {
      this.post('monitornew.error.page', this.errorMsgFormData, function (resp) {
        this.errorMsgData.pageInfo = resp.data;
        this.logDetailVisible = true;
      });
    },
    loadInstanceMonitorInfo: function loadInstanceMonitorInfo(row, treeNode, resolve) {
      this.post('monitornew.routeid.data.get', {
        routeId: row.routeId
      }, function (resp) {
        var children = resp.data;
        row.children = children;
        resolve(children);
      });
    },
    onShowErrorDetail: function onShowErrorDetail(row) {
      this.errorMsgData.title = "\u9519\u8BEF\u65E5\u5FD7 ".concat(row.name, "\uFF08").concat(row.version, "\uFF09");
      this.errorMsgData.name = row.name;
      this.errorMsgData.version = row.version;
      this.errorMsgFormData.routeId = row.routeId;
      this.errorMsgFormData.instanceId = row.instanceId;
      this.loadErrorData();
    },
    onSolve: function onSolve(row) {
      this.confirm('确认标记为已解决吗？', function (done) {
        this.post('monitornew.error.solve', {
          routeId: row.routeId,
          errorId: row.errorId
        }, function (resp) {
          done();
          this.errorMsgFormData.pageIndex = 1;
          this.loadErrorData();
        });
      });
    },
    onCloseErrorDlg: function onCloseErrorDlg() {
      this.loadTable();
    },
    onErrorSizeChange: function onErrorSizeChange(size) {
      this.errorMsgFormData.pageSize = size;
      this.loadErrorData();
    },
    onErrorPageIndexChange: function onErrorPageIndexChange(pageIndex) {
      this.errorMsgFormData.pageIndex = pageIndex;
      this.loadErrorData();
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    },
    onAdd: function onAdd() {
      this.dialogTitle = '新增IP';
      this.dialogVisible = true;
      this.dialogFormData.id = 0;
    }
  }
};
exports.default = _default;