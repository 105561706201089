"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tabsData: [],
      tabsActive: '',
      serviceTextLimitSize: 20,
      filterText: '',
      treeData: [],
      tableData: [],
      serviceId: '',
      searchFormData: {
        pageIndex: 1,
        pageSize: 5
      },
      pageInfo: {
        list: [],
        total: 0
      },
      routeList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // dialog
      dlgTitle: '设置限流',
      limitDialogVisible: false,
      limitDialogFormData: {
        id: 0,
        routeId: '',
        appKey: '',
        limitIp: '',
        limitKey: '',
        execCountPerSecond: 5,
        durationSeconds: 1,
        limitCode: '',
        limitMsg: '',
        tokenBucketCount: 5,
        limitStatus: 0,
        // 0: 停用，1：启用
        limitType: 1,
        orderIndex: 0,
        remark: '',
        typeKey: []
      },
      rulesLimit: {
        typeKey: [{
          type: 'array',
          required: true,
          message: '请至少选择一个',
          trigger: 'change'
        }],
        routeId: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度在 1 到 100 个字符',
          trigger: 'blur'
        }],
        appKey: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度在 1 到 100 个字符',
          trigger: 'blur'
        }],
        ip: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }, {
          min: 1,
          max: 500,
          message: '长度在 1 到 500 个字符',
          trigger: 'blur'
        }],
        // window
        execCountPerSecond: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        // token
        tokenBucketCount: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        orderIndex: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        remark: [{
          max: 128,
          message: '长度不能超过128字符',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree2.filter(val);
    }
  },
  created: function created() {
    this.loadTabs();
  },
  methods: {
    loadTabs: function loadTabs() {
      this.post('registry.service.list', {}, function (resp) {
        var _this = this;
        this.tabsData = resp.data;
        this.$nextTick(function () {
          if (_this.tabsData.length > 0) {
            _this.tabsActive = _this.tabsData[0];
            _this.loadLimitData();
          }
        });
      });
    },
    // 加载树
    loadTree: function loadTree() {
      this.post('registry.service.list', {}, function (resp) {
        var respData = resp.data;
        this.treeData = this.convertToTreeData(respData, 0);
      });
    },
    // 树搜索
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 树点击事件
    onNodeClick: function onNodeClick(data, node, tree) {
      if (data.parentId) {
        this.serviceId = data.label;
        this.searchFormData.serviceId = this.serviceId;
        this.loadTable();
        this.loadRouteList(this.serviceId);
      }
    },
    selectTab: function selectTab() {
      this.loadLimitData();
    },
    loadLimitData: function loadLimitData() {
      this.serviceId = this.tabsActive;
      this.searchFormData.serviceId = this.serviceId;
      this.loadTable();
      this.loadRouteList(this.serviceId);
    },
    /**
     * 数组转成树状结构
     * @param data 数据结构 [{
        "_parentId": 14,
        "gmtCreate": "2019-01-15 09:44:38",
        "gmtUpdate": "2019-01-15 09:44:38",
        "id": 15,
        "isShow": 1,
        "name": "用户注册",
        "orderIndex": 10000,
        "parentId": 14
    },...]
     * @param pid 初始父节点id，一般是0
     * @return 返回结果 [{
      label: '一级 1',
      children: [{
        label: '二级 1-1',
        children: [{
          label: '三级 1-1-1'
        }]
      }]
    }
     */
    convertToTreeData: function convertToTreeData(data, pid) {
      var result = [];
      var root = {
        label: data.length === 0 ? '无服务' : '服务列表',
        parentId: pid
      };
      var children = [];
      for (var i = 0; i < data.length; i++) {
        var child = {
          parentId: 1,
          label: data[i]
        };
        children.push(child);
      }
      root.children = children;
      result.push(root);
      return result;
    },
    // table
    loadTable: function loadTable() {
      this.post('config.limit.list', this.searchFormData, function (resp) {
        this.pageInfo = resp.data;
      });
    },
    loadRouteList: function loadRouteList(serviceId) {
      this.post('route.list/1.2', {
        serviceId: serviceId
      }, function (resp) {
        this.routeList = resp.data;
      });
    },
    onAdd: function onAdd() {
      if (!this.serviceId) {
        this.tip('请选择服务', 'info');
        return;
      }
      this.dlgTitle = '新增限流';
      this.limitDialogFormData.id = 0;
      this.limitDialogVisible = true;
    },
    onSearchTable: function onSearchTable() {
      this.searchFormData.pageIndex = 1;
      this.loadTable();
    },
    onTableUpdate: function onTableUpdate(row) {
      var _this2 = this;
      this.dlgTitle = '修改限流';
      this.limitDialogVisible = true;
      this.$nextTick(function () {
        Object.assign(_this2.limitDialogFormData, row);
        if (row.routeId) {
          _this2.limitDialogFormData.typeKey.push(1);
        }
        if (row.appKey) {
          _this2.limitDialogFormData.typeKey.push(2);
        }
        if (row.limitIp) {
          _this2.limitDialogFormData.typeKey.push(3);
        }
      });
    },
    onLimitDialogClose: function onLimitDialogClose() {
      this.resetForm('limitDialogForm');
      this.limitDialogFormData.limitStatus = 0;
    },
    infoRender: function infoRender(row) {
      if (row.limitType === 1) {
        var durationSeconds = row.durationSeconds;
        return "\u6BCF ".concat(durationSeconds, " \u79D2\u53EF\u5904\u7406 ").concat(row.execCountPerSecond, " \u4E2A\u8BF7\u6C42");
      } else if (row.limitType === 2) {
        return "\u4EE4\u724C\u6876\u5BB9\u91CF\uFF1A".concat(row.tokenBucketCount);
      }
    },
    onLimitDialogSave: function onLimitDialogSave() {
      var _this3 = this;
      this.$refs['limitDialogForm'].validate(function (valid) {
        if (valid) {
          _this3.cleanCheckboxData();
          _this3.limitDialogFormData.serviceId = _this3.serviceId;
          var uri = _this3.limitDialogFormData.id ? 'config.limit.update' : 'config.limit.add';
          _this3.post(uri, _this3.limitDialogFormData, function (resp) {
            this.limitDialogVisible = false;
            this.loadTable();
          });
        }
      });
    },
    cleanCheckboxData: function cleanCheckboxData() {
      // 如果没有勾选则清空
      if (!this.checkTypeKey(1)) {
        this.limitDialogFormData.routeId = '';
      }
      if (!this.checkTypeKey(2)) {
        this.limitDialogFormData.appKey = '';
      }
      if (!this.checkTypeKey(3)) {
        this.limitDialogFormData.limitIp = '';
      }
    },
    onLimitTypeTipClick: function onLimitTypeTipClick() {
      var windowRemark = '窗口策略：每秒处理固定数量的请求，超出请求数量返回错误信息。';
      var tokenRemark = '令牌桶策略：每秒放置固定数量的令牌数，每个请求进来后先去拿令牌，拿到了令牌才能继续，拿不到则等候令牌重新生成了再拿。';
      var content = windowRemark + '<br>' + tokenRemark;
      this.$alert(content, '限流策略', {
        dangerouslyUseHTMLString: true
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    },
    checkTypeKey: function checkTypeKey(val) {
      return this.limitDialogFormData.typeKey.find(function (value, index, arr) {
        return value === val;
      });
    },
    isWindowType: function isWindowType() {
      return this.limitDialogFormData.limitType === 1;
    },
    isTokenType: function isTokenType() {
      return this.limitDialogFormData.limitType === 2;
    },
    limitRender: function limitRender(row) {
      var html = [];
      var val = [];
      if (row.routeId) {
        val.push(row.routeId);
        html.push('路由ID');
      }
      if (row.appKey) {
        val.push(row.appKey);
        html.push('AppId');
      }
      if (row.limitIp) {
        val.push(row.limitIp);
        html.push('IP');
      }
      return val.join(' + ') + '<br>(' + html.join(' + ') + ')';
    }
  }
};
exports.default = _default;