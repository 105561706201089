var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.tabsData.length === 0
        ? _c("div", [_vm._v("\n    无服务\n  ")])
        : _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.selectTab },
                  model: {
                    value: _vm.tabsActive,
                    callback: function ($$v) {
                      _vm.tabsActive = $$v
                    },
                    expression: "tabsActive",
                  },
                },
                _vm._l(_vm.tabsData, function (tabName) {
                  return _c("el-tab-pane", {
                    key: tabName,
                    attrs: { label: tabName, name: tabName },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    model: _vm.searchFormData,
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "路由ID" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "接口名，支持模糊查询",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchFormData.routeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchFormData, "routeId", $$v)
                          },
                          expression: "searchFormData.routeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AppId" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "AppId，支持模糊查询",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchFormData.appKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchFormData, "appKey", $$v)
                          },
                          expression: "searchFormData.appKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "IP" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "ip，支持模糊查询",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchFormData.limitIp,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchFormData, "limitIp", $$v)
                          },
                          expression: "searchFormData.limitIp",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.onSearchTable },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增限流")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                { attrs: { data: _vm.pageInfo.list, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "limitKey",
                      label: "限流维度",
                      width: "400",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.limitRender(scope.row)),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "limitType",
                        label: "限流策略",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.limitType === 1
                                ? _c("span", [_vm._v("窗口策略")])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.limitType === 2
                                ? _c("span", [_vm._v("令牌桶策略")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _vm._v("\n          限流策略\n          "),
                          _c(
                            "el-popover",
                            {
                              ref: "popover",
                              attrs: {
                                placement: "top",
                                title: "限流策略",
                                width: "500",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", [
                                _c("p", [
                                  _vm._v(
                                    "窗口策略：每秒处理固定数量的请求，超出请求数量返回错误信息。"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "令牌桶策略：每秒放置固定数量的令牌数，每个请求进来后先去拿令牌，拿到了令牌才能继续，拿不到则等候令牌重新生成了再拿。"
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover:popover",
                                arg: "popover",
                              },
                            ],
                            staticClass: "el-icon-question",
                            staticStyle: { cursor: "pointer" },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "info", label: "限流信息", width: "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.infoRender(scope.row)),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "limitStatus", label: "状态", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.limitStatus === 1
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#67C23A" } },
                                  [_vm._v("已开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.limitStatus === 0
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#909399" } },
                                  [_vm._v("已关闭")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "orderIndex", label: "排序", width: "80" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: "备注",
                      width: "150",
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "gmtCreate",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "gmtModified",
                      label: "修改时间",
                      width: "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onTableUpdate(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                staticStyle: { "margin-top": "5px" },
                attrs: {
                  background: "",
                  "current-page": _vm.searchFormData.pageIndex,
                  "page-size": _vm.searchFormData.pageSize,
                  "page-sizes": [5, 10, 20, 40],
                  total: _vm.pageInfo.total,
                  layout: "total, sizes, prev, pager, next",
                },
                on: {
                  "size-change": _vm.onSizeChange,
                  "current-change": _vm.onPageIndexChange,
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dlgTitle,
            visible: _vm.limitDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.limitDialogVisible = $event
            },
            close: _vm.onLimitDialogClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "limitDialogForm",
              attrs: {
                model: _vm.limitDialogFormData,
                rules: _vm.rulesLimit,
                "label-width": "150px",
                size: "mini",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "限流维度", prop: "typeKey" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.limitDialogFormData.typeKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.limitDialogFormData, "typeKey", $$v)
                        },
                        expression: "limitDialogFormData.typeKey",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: 1, name: "typeKey" },
                          model: {
                            value: _vm.limitDialogFormData.typeKey[0],
                            callback: function ($$v) {
                              _vm.$set(_vm.limitDialogFormData.typeKey, 0, $$v)
                            },
                            expression: "limitDialogFormData.typeKey[0]",
                          },
                        },
                        [_vm._v("路由ID")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: 2, name: "typeKey" },
                          model: {
                            value: _vm.limitDialogFormData.typeKey[1],
                            callback: function ($$v) {
                              _vm.$set(_vm.limitDialogFormData.typeKey, 1, $$v)
                            },
                            expression: "limitDialogFormData.typeKey[1]",
                          },
                        },
                        [_vm._v("AppId")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: 3, name: "typeKey" },
                          model: {
                            value: _vm.limitDialogFormData.typeKey[2],
                            callback: function ($$v) {
                              _vm.$set(_vm.limitDialogFormData.typeKey, 2, $$v)
                            },
                            expression: "limitDialogFormData.typeKey[2]",
                          },
                        },
                        [_vm._v("IP")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkTypeKey(1),
                      expression: "checkTypeKey(1)",
                    },
                  ],
                  attrs: {
                    prop: "routeId",
                    label: "路由ID",
                    rules: _vm.checkTypeKey(1) ? _vm.rulesLimit.routeId : [],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { filterable: "", placeholder: "可筛选" },
                      model: {
                        value: _vm.limitDialogFormData.routeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.limitDialogFormData, "routeId", $$v)
                        },
                        expression: "limitDialogFormData.routeId",
                      },
                    },
                    _vm._l(_vm.routeList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.id, value: item.id },
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "right",
                                color: "#8492a6",
                                "font-size": "13px",
                              },
                            },
                            [_vm._v(_vm._s(item.version))]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkTypeKey(2),
                      expression: "checkTypeKey(2)",
                    },
                  ],
                  attrs: {
                    prop: "appKey",
                    label: "AppId",
                    rules: _vm.checkTypeKey(2) ? _vm.rulesLimit.appKey : [],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "需要限流的AppId" },
                    model: {
                      value: _vm.limitDialogFormData.appKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.limitDialogFormData, "appKey", $$v)
                      },
                      expression: "limitDialogFormData.appKey",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkTypeKey(3),
                      expression: "checkTypeKey(3)",
                    },
                  ],
                  attrs: {
                    label: "限流IP",
                    prop: "limitIp",
                    rules: _vm.checkTypeKey(3) ? _vm.rulesLimit.ip : [],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "多个用英文逗号隔开",
                    },
                    model: {
                      value: _vm.limitDialogFormData.limitIp,
                      callback: function ($$v) {
                        _vm.$set(_vm.limitDialogFormData, "limitIp", $$v)
                      },
                      expression: "limitDialogFormData.limitIp",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "限流策略" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.limitDialogFormData.limitType,
                        callback: function ($$v) {
                          _vm.$set(_vm.limitDialogFormData, "limitType", $$v)
                        },
                        expression: "limitDialogFormData.limitType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("窗口策略"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("令牌桶策略"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-popover",
                    {
                      ref: "popover",
                      attrs: {
                        placement: "top",
                        title: "限流策略",
                        width: "500",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("div", [
                        _c("p", [
                          _vm._v(
                            "窗口策略：每秒处理固定数量的请求，超出请求数量返回错误信息。"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "令牌桶策略：每秒放置固定数量的令牌数，每个请求进来后先去拿令牌，拿到了令牌才能继续，拿不到则等候令牌重新生成了再拿。"
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "popover",
                        rawName: "v-popover:popover",
                        arg: "popover",
                      },
                    ],
                    staticClass: "el-icon-question",
                    staticStyle: { cursor: "pointer" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "开启状态" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.limitDialogFormData.limitStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.limitDialogFormData, "limitStatus", $$v)
                      },
                      expression: "limitDialogFormData.limitStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "orderIndex" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.limitDialogFormData.orderIndex,
                      callback: function ($$v) {
                        _vm.$set(_vm.limitDialogFormData, "orderIndex", $$v)
                      },
                      expression: "limitDialogFormData.orderIndex",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "tip",
                      staticStyle: { "margin-left": "10px" },
                    },
                    [_vm._v("值小优先执行")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isWindowType(),
                      expression: "isWindowType()",
                    },
                  ],
                  attrs: {
                    label: "请求数",
                    prop: "execCountPerSecond",
                    rules: _vm.isWindowType()
                      ? _vm.rulesLimit.execCountPerSecond
                      : [],
                  },
                },
                [
                  _vm._v("\n        每 "),
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.limitDialogFormData.durationSeconds,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.limitDialogFormData,
                          "durationSeconds",
                          $$v
                        )
                      },
                      expression: "limitDialogFormData.durationSeconds",
                    },
                  }),
                  _vm._v(" 秒可处理\n        "),
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.limitDialogFormData.execCountPerSecond,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.limitDialogFormData,
                          "execCountPerSecond",
                          $$v
                        )
                      },
                      expression: "limitDialogFormData.execCountPerSecond",
                    },
                  }),
                  _vm._v(" 个请求\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isTokenType(),
                      expression: "isTokenType()",
                    },
                  ],
                  attrs: {
                    label: "令牌桶容量",
                    prop: "tokenBucketCount",
                    rules: _vm.isTokenType()
                      ? _vm.rulesLimit.tokenBucketCount
                      : [],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.limitDialogFormData.tokenBucketCount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.limitDialogFormData,
                          "tokenBucketCount",
                          $$v
                        )
                      },
                      expression: "limitDialogFormData.tokenBucketCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.limitDialogFormData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.limitDialogFormData, "remark", $$v)
                      },
                      expression: "limitDialogFormData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.limitDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onLimitDialogSave },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }