var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.searchFormData, size: "mini" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "接口名" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { clearable: true },
                model: {
                  value: _vm.searchFormData.routeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchFormData, "routeId", $$v)
                  },
                  expression: "searchFormData.routeId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "serviceId" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { clearable: true },
                model: {
                  value: _vm.searchFormData.serviceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchFormData, "serviceId", $$v)
                  },
                  expression: "searchFormData.serviceId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    "native-type": "submit",
                  },
                  on: { click: _vm.loadTable },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.pageInfo.list,
            "row-key": "id",
            lazy: "",
            "empty-text": "无数据",
            load: _vm.loadInstanceMonitorInfo,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "instanceId",
              label: "网关实例",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.children
                      ? _c("span", [_vm._v(_vm._s(scope.row.instanceId))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "name",
              label: "接口名 (版本号)",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.name +
                            (scope.row.version
                              ? " (" + scope.row.version + ")"
                              : "")
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "serviceId", label: "serviceId", width: "150" },
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { prop: "maxTime", label: "最大耗时(ms)" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v("\n        最大耗时(ms)\n        "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "耗时计算：签名验证成功后开始，应用返回结果后结束",
                        placement: "top",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { cursor: "pointer" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "minTime", label: "最小耗时(ms)" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "avgTime", label: "平均耗时(ms)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.avgTime.toFixed(1)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "totalRequestCount", label: "总调用次数" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "successCount", label: "成功次数" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "errorCount", label: "失败次数" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "unsolvedErrorCount", label: "未解决错误" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.unsolvedErrorCount > 0
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "text-decoration": "underline" },
                            attrs: { underline: false, type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.onShowErrorDetail(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.unsolvedErrorCount) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.unsolvedErrorCount === 0
                      ? _c("span", [_vm._v("0")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticStyle: { "margin-top": "5px" },
        attrs: {
          background: "",
          "current-page": _vm.searchFormData.pageIndex,
          "page-size": _vm.searchFormData.pageSize,
          "page-sizes": [5, 10, 20, 40],
          total: _vm.pageInfo.total,
          layout: "total, sizes, prev, pager, next",
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.onPageIndexChange,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.errorMsgData.title,
            visible: _vm.logDetailVisible,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.logDetailVisible = $event
            },
            close: _vm.onCloseErrorDlg,
          },
        },
        [
          _c("el-alert", {
            staticClass: "el-alert-tip",
            attrs: { title: "修复错误后请标记解决", closable: false },
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.errorMsgData.pageInfo.rows,
                "empty-text": "无错误日志",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 8, readonly: "" },
                          model: {
                            value: props.row.errorMsg,
                            callback: function ($$v) {
                              _vm.$set(props.row, "errorMsg", $$v)
                            },
                            expression: "props.row.errorMsg",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "errorMsg", label: "错误内容" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        props.row.errorMsg.length > 50
                          ? _c("span", [
                              _vm._v(
                                _vm._s(props.row.errorMsg.substring(0, 50)) +
                                  "..."
                              ),
                            ])
                          : _c("span", [_vm._v(_vm._s(props.row.errorMsg))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "instanceId", label: "实例ID", width: "150px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "count", label: "报错次数", width: "80px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "gmtModified",
                  label: "报错时间",
                  width: "160px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.onSolve(scope.row)
                              },
                            },
                          },
                          [_vm._v("标记解决")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-top": "5px" },
            attrs: {
              background: "",
              "current-page": _vm.errorMsgFormData.pageIndex,
              "page-size": _vm.errorMsgFormData.pageSize,
              "page-sizes": [5, 10, 20, 40],
              total: _vm.errorMsgData.pageInfo.total,
              layout: "total, sizes, prev, pager, next",
            },
            on: {
              "size-change": _vm.onErrorSizeChange,
              "current-change": _vm.onErrorPageIndexChange,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.logDetailVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }