"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    var validateSecret = function validateSecret(rule, value, callback) {
      if (_this.isvKeysFormData.signType === 2) {
        if (value === '') {
          callback(new Error('不能为空'));
        }
        if (value.length > 200) {
          callback(new Error('长度不能超过200'));
        }
      }
      callback();
    };
    var validatePubPriKey = function validatePubPriKey(rule, value, callback) {
      if (_this.isvKeysFormData.signType === 1) {
        if (value === '') {
          callback(new Error('不能为空'));
        }
      }
      callback();
    };
    return {
      isvKeysFormData: {
        appKey: '',
        secret: '',
        keyFormat: 1,
        publicKeyIsv: '',
        privateKeyIsv: '',
        publicKeyPlatform: '',
        privateKeyPlatform: '',
        signType: 1
      },
      rulesIsvKeysForm: {
        secret: [{
          validator: validateSecret,
          trigger: 'blur'
        }],
        publicKeyIsv: [{
          validator: validatePubPriKey,
          trigger: 'blur'
        }],
        privateKeyIsv: [{
          validator: validatePubPriKey,
          trigger: 'blur'
        }]
      },
      activeName: 'first'
    };
  },
  created: function created() {
    var query = this.$route.query;
    this.isvKeysFormData.appKey = query.appKey;
    this.loadForm();
  },
  methods: {
    loadForm: function loadForm() {
      this.post('isv.keys.get', {
        appKey: this.isvKeysFormData.appKey
      }, function (resp) {
        Object.assign(this.isvKeysFormData, resp.data);
      });
    },
    selfLabel: function selfLabel(lab) {
      return '★ ' + lab;
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$refs.isvKeysForm.validate(function (valid) {
        if (valid) {
          _this2.post('isv.keys.update', _this2.isvKeysFormData, function () {
            this.tip('保存成功');
          });
        }
      });
    },
    onBack: function onBack() {
      this.$router.push({
        path: 'list'
      });
    },
    onGenKeysPlatform: function onGenKeysPlatform() {
      this.post('isv.keys.gen', {}, function (resp) {
        this.tip('生成公私钥成功');
        var data = resp.data;
        this.isvKeysFormData.publicKeyPlatform = data.publicKey;
        this.isvKeysFormData.privateKeyPlatform = data.privateKey;
      });
    },
    onGenKeysIsv: function onGenKeysIsv() {
      this.post('isv.keys.gen', {
        keyFormat: this.isvKeysFormData.keyFormat
      }, function (resp) {
        this.tip('生成公私钥成功');
        var data = resp.data;
        this.isvKeysFormData.publicKeyIsv = data.publicKey;
        this.isvKeysFormData.privateKeyIsv = data.privateKey;
      });
    },
    onGenSecret: function onGenSecret() {
      this.post('isv.secret.gen', {}, function (resp) {
        this.isvKeysFormData.secret = resp.data;
      });
    },
    showKeys: function showKeys() {
      return this.isvKeysFormData.signType === 1;
    }
  }
};
exports.default = _default;