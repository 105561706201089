var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.searchFormData, size: "mini" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "serviceId" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                attrs: { clearable: true, placeholder: "serviceId" },
                model: {
                  value: _vm.searchFormData.serviceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchFormData, "serviceId", $$v)
                  },
                  expression: "searchFormData.serviceId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onSearchTable },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { data: _vm.tableData, border: "", "row-key": "id" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "serviceId", label: "服务名称", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.renderServiceName(scope.row)),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "ipPort", label: "IP端口", width: "250" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "metadata", label: "当前环境", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === "UP"
                      ? _c(
                          "div",
                          [
                            scope.row.parentId > 0 &&
                            scope.row.metadata.env === "pre"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("预发布"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.parentId > 0 &&
                            scope.row.metadata.env === "gray"
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("灰度"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.parentId > 0 && !scope.row.metadata.env
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("线上"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "metadata", label: "metadata", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.parentId > 0
                      ? _c("span", [
                          _vm._v(_vm._s(JSON.stringify(scope.row.metadata))),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "服务状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.parentId > 0 && scope.row.status === "UP"
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("正常"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.parentId > 0 && scope.row.status === "STARTING"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("正在启动"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.parentId > 0 && scope.row.status === "UNKNOWN"
                      ? _c("el-tag", [_vm._v("未知")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.parentId > 0 &&
                    (scope.row.status === "OUT_OF_SERVICE" ||
                      scope.row.status === "DOWN")
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("已禁用"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.blackList.indexOf(scope.row.serviceId.toLowerCase()) < 0
                      ? _c("div", [
                          scope.row.parentId === 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGrayConfigUpdate(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("设置灰度参数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onRouteConfigUpdate(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("设置路由")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.parentId > 0
                            ? _c("div", [
                                scope.row.status === "UP"
                                  ? _c(
                                      "span",
                                      [
                                        scope.row.metadata.env === "pre"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEnvPreClose(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("结束预发布")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.metadata.env === "gray"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEnvGrayClose(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("结束灰度")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !scope.row.metadata.env
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEnvPreOpen(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("开启预发布")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !scope.row.metadata.env
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEnvGrayOpen(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("开启灰度")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    scope.row.status === "UP"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onDisable(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("禁用")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.status === "OUT_OF_SERVICE"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onEnable(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("启用")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "灰度设置",
            visible: _vm.grayDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.grayDialogVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm("grayForm")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "grayForm",
              attrs: {
                model: _vm.grayForm,
                rules: _vm.grayFormRules,
                size: "mini",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "serviceId" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.grayForm.serviceId) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.tabsActiveName,
                    callback: function ($$v) {
                      _vm.tabsActiveName = $$v
                    },
                    expression: "tabsActiveName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "灰度用户", name: "first" } },
                    [
                      _c("el-alert", {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: {
                          title: "可以是AppId或IP地址，多个用英文逗号隔开",
                          type: "info",
                          closable: false,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "userKeyContent" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder:
                                "可以是AppId或IP地址，多个用英文逗号隔开",
                              type: "textarea",
                              rows: 6,
                            },
                            model: {
                              value: _vm.grayForm.userKeyContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.grayForm, "userKeyContent", $$v)
                              },
                              expression: "grayForm.userKeyContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "接口配置", name: "second" } },
                    [
                      _c("el-alert", {
                        attrs: {
                          title: "灰度接口：接口名相同，版本号不同",
                          type: "info",
                          closable: false,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.addNameVersion },
                            },
                            [_vm._v("新增灰度接口")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        { attrs: { cellpadding: "0", cellspacing: "0" } },
                        _vm._l(
                          _vm.grayForm.grayRouteConfigList,
                          function (grayRouteConfig, index) {
                            return _c("tr", { key: grayRouteConfig.key }, [
                              _c(
                                "td",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: grayRouteConfig.key,
                                      attrs: {
                                        prop:
                                          "grayRouteConfigList." +
                                          index +
                                          ".oldRouteId",
                                        rules: {
                                          required: true,
                                          message: "不能为空",
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  老接口：\n                  "
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                            width: "250px",
                                          },
                                          attrs: { filterable: "" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onChangeOldRoute(
                                                grayRouteConfig
                                              )
                                            },
                                          },
                                          model: {
                                            value: grayRouteConfig.oldRouteId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                grayRouteConfig,
                                                "oldRouteId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "grayRouteConfig.oldRouteId",
                                          },
                                        },
                                        _vm._l(_vm.routeList, function (route) {
                                          return _c("el-option", {
                                            key: route.id,
                                            attrs: {
                                              label:
                                                route.name +
                                                "(" +
                                                route.version +
                                                ")",
                                              value: route.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      key: grayRouteConfig.key + 1,
                                      attrs: {
                                        prop:
                                          "grayRouteConfigList." +
                                          index +
                                          ".newVersion",
                                        rules: {
                                          required: true,
                                          message: "不能为空",
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  灰度接口：\n                  "
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            filterable: "",
                                            "no-data-text": "无数据",
                                          },
                                          model: {
                                            value: grayRouteConfig.newVersion,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                grayRouteConfig,
                                                "newVersion",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "grayRouteConfig.newVersion",
                                          },
                                        },
                                        _vm._l(
                                          _vm.getGraySelectData(
                                            grayRouteConfig.oldRouteId
                                          ),
                                          function (routeNew) {
                                            return _c("el-option", {
                                              key: routeNew.id,
                                              attrs: {
                                                label:
                                                  routeNew.name +
                                                  "(" +
                                                  routeNew.version +
                                                  ")",
                                                value: routeNew.version,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: { "vertical-align": "baseline" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.grayForm.grayRouteConfigList
                                              .length > 1,
                                          expression:
                                            "grayForm.grayRouteConfigList.length > 1",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeNameVersion(
                                            grayRouteConfig
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.grayDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onGrayConfigSave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "路由设置",
            visible: _vm.routeDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.routeDialogVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm("routeForm")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "routeForm",
              attrs: {
                model: _vm.routeForm,
                rules: _vm.grayFormRules,
                size: "mini",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "serviceId" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.routeForm.serviceId) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "路由断言", prop: "predicates" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "路由断言", type: "input" },
                    model: {
                      value: _vm.routeForm.predicates,
                      callback: function ($$v) {
                        _vm.$set(_vm.routeForm, "predicates", $$v)
                      },
                      expression: "routeForm.predicates",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "目标url", prop: "uri" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "目标url", type: "input" },
                    model: {
                      value: _vm.routeForm.uri,
                      callback: function ($$v) {
                        _vm.$set(_vm.routeForm, "uri", $$v)
                      },
                      expression: "routeForm.uri",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.routeDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onRouteConfigSave },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }